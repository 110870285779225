var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "max-width": "1270px",
      "width": "100%",
      "margin-inline": "auto",
      "margin-top": ['0px', '30px'],
      "padding": ['0', '30px 20px'],
      "border-radius": ['0px', '16px'],
      "box-shadow": ['none', '2px 2px 10px 0px #00000026'],
      "background-color": ['neutral.superLightGray', '#FFF'],
      "height": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "display": ['none', 'flex'],
      "width": "100%",
      "px": "30px",
      "py": "5px",
      "mb": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "align-item": "center",
      "font-size": "16px",
      "font-weight": "500",
      "color": "primary.400",
      "gap": "20px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.profile.referral'
        });
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-left.svg'),
      "height": "20",
      "width": "20"
    }
  }), _vm._v(" Kembali ")], 1)], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1120px",
      "min-height": ['calc(100vh - 62px)', '300px'],
      "background-color": ['neutral.superLightGray', 'unset']
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "text-align": "center",
      "font-size": "28px",
      "font-weight": "700"
    }
  }, [_vm._v(" Daftar Pengguna Referral ")]), _c('c-box', {
    attrs: {
      "as": "ul",
      "list-style-type": "none",
      "padding": "16px 16px 0 16px",
      "width": "100%",
      "max-width": "500px",
      "margin": "auto"
    }
  }, _vm._l(_vm.details, function (detail, detailIdx) {
    return _c('c-box', {
      key: detailIdx,
      attrs: {
        "as": "li",
        "box-shadow": "2px 2px 10px 0px #00000026",
        "padding": "20px 16px",
        "border-radius": "12px",
        "background-color": "white",
        "display": "flex",
        "gap": "12px",
        "align-items": "center",
        "flex-wrap": "wrap",
        "margin-bottom": ['12px', '16px']
      }
    }, [_c('c-image', {
      attrs: {
        "border-radius": "50%",
        "src": _vm.getPhotoUser(detail === null || detail === void 0 ? void 0 : detail.photoUrl),
        "height": ['50px', '30px'],
        "width": ['50px', '30px']
      }
    }), _c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "gap": "4px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s((detail === null || detail === void 0 ? void 0 : detail.email) || '-') + " ")]), _c('c-text', {
      attrs: {
        "font-size": "12px",
        "font-weight": "400"
      }
    }, [_vm._v(" " + _vm._s(detail !== null && detail !== void 0 && detail.date ? _vm.formatDateV2(detail === null || detail === void 0 ? void 0 : detail.date) : '-') + " ")])], 1), _c('c-text', {
      attrs: {
        "margin-left": "auto",
        "font-size": "12px",
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(detail !== null && detail !== void 0 && detail.point ? "+".concat(detail === null || detail === void 0 ? void 0 : detail.point, " poin") : '-') + " ")])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }