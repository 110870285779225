<template>
  <c-box
    max-width="1270px"
    width="100%"
    margin-inline="auto"
    :margin-top="['0px', '30px']"
    :padding="['0', '30px 20px']"
    :border-radius="['0px', '16px']"
    :box-shadow="['none', '2px 2px 10px 0px #00000026']"
    :background-color="['neutral.superLightGray', '#FFF']"
    height="100%"
  >
    <c-box
      :display="['none', 'flex']"
      width="100%"
      px="30px"
      py="5px"
      mb="20px"
    >
      <c-box
        display="flex"
        align-item="center"
        font-size="16px"
        font-weight="500"
        color="primary.400"
        gap="20px"
        cursor="pointer"
        @click="$router.push({ name: 'client.profile.referral' })"
      >
        <inline-svg
          :src="require('@/assets/icon-chevron-left.svg')"
          height="20"
          width="20"
        />
        Kembali
      </c-box>
    </c-box>
    <c-box
      width="100%"
      max-width="1120px"
      :min-height="['calc(100vh - 62px)', '300px']"
      :background-color="['neutral.superLightGray', 'unset']"
    >
      <c-text
        :display="['none', 'block']"
        text-align="center"
        font-size="28px"
        font-weight="700"
      >
        Daftar Pengguna Referral
      </c-text>

      <c-box
        as="ul"
        list-style-type="none"
        padding="16px 16px 0 16px"
        width="100%"
        max-width="500px"
        margin="auto"
      >
        <c-box
          v-for="(detail, detailIdx) in details"
          :key="detailIdx"
          as="li"
          box-shadow="2px 2px 10px 0px #00000026"
          padding="20px 16px"
          border-radius="12px"
          background-color="white"
          display="flex"
          gap="12px"
          align-items="center"
          flex-wrap="wrap"
          :margin-bottom="['12px', '16px']"
        >
          <c-image
            border-radius="50%"
            :src="getPhotoUser(detail?.photoUrl)"
            :height="['50px', '30px']"
            :width="['50px', '30px']"
          />
          <c-flex
            flex-direction="column"
            gap="4px"
          >
            <c-text
              font-size="14px"
              font-weight="500"
            >
              {{ detail?.email || '-' }}
            </c-text>
            <c-text
              font-size="12px"
              font-weight="400"
            >
              {{ detail?.date ? formatDateV2(detail?.date) : '-' }}
            </c-text>
          </c-flex>
          <c-text
            margin-left="auto"
            font-size="12px"
            font-weight="400"
            color="primary.400"
          >
            {{ detail?.point ? `+${detail?.point} poin` : '-' }}
          </c-text>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { reqReferral } from '@/requests/dietela-api/referral'
import { CBox, CImage, CText, CFlex } from '@chakra-ui/vue'
import { formatDateV2 } from '@/utils/format-date'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'Redeem',
  components: {
    CBox,
    CText,
    CImage,
    CFlex,
  },
  mixins: [generalMixin],
  data() {
    return {
      details: [],
    }
  },
  mounted() {
    reqReferral.detailClientUse(this.$store.getters.axios).then((v) => {
      this.details = v?.data?.data || []
    })
  },
  methods: {
    formatDateV2,
  },
}
</script>
